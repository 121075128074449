<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Module Group Management</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row mb-3">
                <label for="name" class="col-md-3 form-label">
                  Module Group Name
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-6">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    class="form-control"
                    placeholder="Module Group Name"
                    v-model="module_group.name"
                    :class="{
                      'p-invalid':
                        v$.module_group.name.$error || errorFor('name'),
                    }"
                  />
                  <v-errors
                    :serverErrors="errorFor('name')"
                    :vuelidateErrors="{
                      errors: v$.module_group.name.$errors,
                      value: 'Name',
                    }"
                  ></v-errors>
                </div>
              </div>
              <div class="row mb-3">
                <label for="employee_type" class="col-md-3 form-label">
                  Employee Type
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-6">
                  <select
                    class="form-control"
                    v-model="module_group.employee_type_id"
                    :class="{
                      'p-invalid':
                        v$.module_group.employee_type_id.$error ||
                        errorFor('Employee Type'),
                    }"
                  >
                    <option
                      v-for="employee_type in employee_types"
                      :key="employee_type.id"
                      :value="employee_type.id"
                    >
                      {{ employee_type.name }}
                    </option>
                  </select>
                  <v-errors
                    :serverErrors="errorFor('employee_type_id')"
                    :vuelidateErrors="{
                      errors: v$.module_group.employee_type_id.$errors,
                      value: 'Employee Type',
                    }"
                  ></v-errors>
                </div>
              </div>
              <div class="row mb-3">
                <label for="category" class="col-md-3 form-label">
                  Module Category
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-6">
                  <select
                    class="form-control"
                    v-model="module_group.category_id"
                    @change="changeModule($event)"
                    :class="{
                      'p-invalid':
                        v$.module_group.category_id.$error ||
                        errorFor('Category'),
                    }"
                  >
                    <option
                      v-for="category in categories"
                      :key="category.id"
                      :value="category.id"
                    >
                      {{ category.name }}
                    </option>
                  </select>
                  <v-errors
                    :serverErrors="errorFor('category_id')"
                    :vuelidateErrors="{
                      errors: v$.module_group.category_id.$errors,
                      value: 'Category',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3" v-if="module_group.category_id == '3'">
                <label for="job_field" class="col-md-3 form-label">
                  Job Field
                </label>
                <div class="col-md-6">
                  <v-select
                    class="custom-selectCSS"
                    v-model="module_group.job_field_id"
                    label="name"
                    :options="job_fields"
                    :reduce="(name) => name.id"
                    :clearable="false"
                  ></v-select>
                </div>
              </div>

              <div class="row mb-3">
                <label for="module" class="col-md-3 form-label">
                  Module
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-6">
                  <!-- @search:focus="changingModule()" -->
                  <v-select
                    multiple
                    class="custom-selectCSS"
                    v-model="module_group.modules"
                    label="name"
                    :options="filter_modules"
                    :class="{
                      'p-invalid':
                        v$.module_group.modules.$error || errorFor('modules'),
                    }"
                  ></v-select>

                  <v-errors
                    :serverErrors="errorFor('modules')"
                    :vuelidateErrors="{
                      errors: v$.module_group.modules.$errors,
                      value: 'Modules',
                    }"
                  ></v-errors>
                </div>
              </div>
              <div class="row mb-3" v-if="module_group.employee_type_id == '1'">
                <label for="program" class="col-md-3 form-label">
                  Program Name
                </label>
                <div class="col-md-6">
                  <v-select
                    class="custom-selectCSS"
                    v-model="module_group.program_id"
                    label="name"
                    :options="programs"
                    :reduce="(name) => name.id"
                  ></v-select>
                </div>
              </div>

              <div class="row mb-3" v-if="module_group.employee_type_id == '2'">
                <label for="position_levels" class="col-md-3 form-lable">
                  Position Level
                </label>
                <div class="col-md-6">
                  <v-select
                    multiple
                    class="custom-selectCSS"
                    v-model="module_group.position_levels"
                    label="name"
                    :options="position_levels_id"
                    :reduce="(name) => name.id"
                  ></v-select>
                </div>
              </div>
              <!-- end row -->
              <div class="row">
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'module-groups' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName === 'module-group-update'
                          ? updateModuleGroup()
                          : createModuleGroup()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../mixins/validationErrors";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  mixins: [validationErrors],
  data() {
    return {
      selected: null,
      modules: "",
      programs: [],
      job_fields: [],
      filter_jobfield: [],
      employee_types: "",
      categories: "",
      filter_modules: [],
      position_levels_id: [],
      module_group: {
        name: "",
        employee_type_id: "",
        category_id: "",
        modules: [],
        program_id: "",
        job_field_id: "",
        position_levels: [],
      },
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
      loading: false,
      isLoading: false,
    };
  },
  validations() {
    return {
      module_group: {
        name: { required },
        employee_type_id: { required },
        category_id: { required },
        modules: { required },
      },
    };
  },
  methods: {
    async getCategories() {
      await axios
        .get(`${this.baseUrl}admin/v1/categories`)
        .then((response) => {
          this.categories = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Categories!");
        });
    },
    async getModules() {
      await axios
        .get(`${this.baseUrl}admin/v1/modules`)
        .then((response) => {
          this.modules = response.data.data.map(function (val) {
            return {
              id: val.id,
              name: val.name,
              category_id: val.category_id,
            };
          });
        })
        .catch(() => {
          this.toast.error("Not Found Module!");
        });
    },
    async getPrograms() {
      await axios
        .get(`${this.baseUrl}admin/v1/programs`)
        .then((response) => {
          this.programs = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },
    async getJobFields() {
      await axios
        .get(`${this.baseUrl}admin/v1/jobfield`)
        .then((response) => {
          this.job_fields = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Job Fields!");
        });
    },
    async getPositionLevels() {
      await axios
        .get(`${this.baseUrl}admin/v1/position-level`)
        .then((response) => {
          this.position_levels_id = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Position Levels!");
        });
    },
    async getEmployeeType() {
      await axios
        .get(`${this.baseUrl}admin/v1/employee-types`)
        .then((response) => {
          this.employee_types = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Employee Type!");
        });
    },

    async getModuleGroupById() {
      this.loading = true;
      await this.getModules();
      await this.getJobFields();
      await axios
        .get(`${this.baseUrl}admin/v1/module-groups/${this.$route.params.id}`)
        .then((response) => {
          const result = response.data.data;
          this.module_group = result;
          this.module_group.program = result.program_name;
          this.module_group.job_field_id = result.job_field_id;
          this.module_group.position_levels = JSON.parse(
            result.position_levels
          );
          console.log(this.modules, "hello");
          this.filter_modules = [];
          this.filter_modules = this.modules.filter((module) => {
            if (module.category_id == this.module_group.category_id) {
              return module;
            } else if (this.module_group.category_id == 1) {
              return module;
            }
          });
          // console.log(this.filter_modules, "filter");
        })
        .catch(() => {
          this.toast.error("Not Found Module Group!");
        });
      this.loading = false;
    },

    changeModule(event) {
      let id = event.target.value;
      this.module_group.modules = null;
      this.module_group.job_field_id = null;
      this.filter_modules = [];
      this.filter_modules = this.modules.filter((module) => {
        if (module.category_id == id) {
          return module;
        } else if (id == 1) {
          return module;
        }
      });
    },
    //  changingModule() {
    //    this.filter_modules = this.modules.filter((module) => {
    //      if (module.category_id == this.module_group.category_id) {
    //        return module;
    //      }
    //    });
    //  },

    async createModuleGroup() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      let form = new FormData();
      if (this.module_group.category_id == "3") {
        form.append("job_field_id", this.module_group.job_field_id);
      }

      if (this.module_group.employee_type_id == "2") {
        var formatedPositionLevels = JSON.stringify(
          this.module_group.position_levels
        );
        form.append("position_levels", formatedPositionLevels);
      } else {
        form.append("program_id", this.module_group.program_id);
      }
      this.module_group.modules.forEach((module) => {
        form.append("module_ids[]", module.id);
      });

      form.append("name", this.module_group.name);
      form.append("category_id", this.module_group.category_id);
      form.append("employee_type_id", this.module_group.employee_type_id);

      this.isLoading = true;

      await axios
        .post(`${this.baseUrl}admin/v1/module-groups`, form)
        .then(() => {
          this.$router.push({ name: "module-groups" });
          this.toast.success("Successfully Created Module Group!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },

    async updateModuleGroup() {
      let updateForm = new FormData();
      // Job Field
      if (this.module_group.category_id == "3") {
        updateForm.append("job_field_id", this.module_group.job_field_id);
      }

      if (this.module_group.employee_type_id == "2") {
        var formatedPositionLevels = JSON.stringify(
          this.module_group.position_levels
        );
        updateForm.append("position_levels", formatedPositionLevels);
      } else {
        updateForm.append("program_id", this.module_group.program_id);
      }

      this.module_group.modules.forEach((module) => {
        updateForm.append("module_ids[]", module.id);
      });

      updateForm.append("name", this.module_group.name);
      updateForm.append("category_id", this.module_group.category_id);
      updateForm.append("employee_type_id", this.module_group.employee_type_id);
      updateForm.append("_method", "put");

      this.isLoading = true;
      axios
        .post(
          `${this.baseUrl}admin/v1/module-groups/${this.$route.params.id}`,
          updateForm,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.$router.push({ name: "module-groups" });
          this.toast.success("Successfully Module Group Updated!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getEmployeeType();
    this.getCategories();

    if (this.currentRouteName == "module-group-update") {
      this.getModuleGroupById();
    }

    if (this.currentRouteName == "module-group-create") {
      this.getModules();
      this.getJobFields();
    }

    this.getPrograms();
    this.getPositionLevels();
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  components: {
    vSelect,
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
</style>
